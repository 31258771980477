import React from 'react';

import { Page } from '@tager/web-components';

import Login from '@/modules/Login';
import { CustomApp_PageContext } from '@/typings/hocs';
import { redirect } from '@/utils/common';
import NotFoundPage from '@/pages/404';
import { getIsUserAuthorized } from '@/store/reducers/auth';

type Props = { pageType: 'NOT_FOUND' | 'CABINET_LOGIN_PAGE' };

function LoginPage(props: Props) {
  if (props.pageType === 'NOT_FOUND') {
    return <NotFoundPage />;
  }

  return (
    <Page title="Личный кабинет Атлант-М">
      <Login />
    </Page>
  );
}

LoginPage.getInitialProps = async ({
  query,
  res,
  store,
}: CustomApp_PageContext) => {
  const isUserAuthorized = getIsUserAuthorized(store.getState());
  const redirectUrl =
    typeof query.redirect_uri === 'string' && query.redirect_uri
      ? query.redirect_uri
      : '/personal';

  if (isUserAuthorized) {
    redirect(redirectUrl, undefined, res);
  }

  return { pageType: 'CABINET_LOGIN_PAGE' };
};

export default LoginPage;

import React from 'react';
import styled from 'styled-components';
import { GoogleLogin } from 'react-google-login';
// @ts-ignore
import FacebookLogin from 'react-facebook-login';

import { useModal } from '@tager/web-components';
import { isBrowser } from '@tager/web-core';

import { colors } from '@/constants/theme';
import FacebookIcon from '@/assets/svg/login/icon-social-facebook.svg';
import VKIcon from '@/assets/svg/login/icon-social-vk.svg';
import GoogleIcon from '@/assets/svg/login/icon-social-google.svg';
import YandexIcon from '@/assets/svg/login/icon-social-yandex.svg';
import { useTypedDispatch } from '@/store/store';
import { signInUsingSocial } from '@/services/users/users-service';
import { signInWithSocialThunk } from '@/store/reducers/auth';
import AuthUsingSocialWithPhoneModal from '@/components/modals/AuthUsingSocialWithPhoneModal';
import YandexLogin from '@/components/YandexLogin';
import VkLogin from '@/components/VkLogin';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';

interface Props {
  setLoadingAuth: (value: boolean) => void;
}

function FastLogin({ setLoadingAuth }: Props) {
  const dispatch = useTypedDispatch();
  const openModal = useModal();

  function socialAuth(token: string) {
    dispatch(signInWithSocialThunk(token)).catch((error: any) => {
      console.log(error);
    });
  }

  const responseSocial = async (social: string, response: any) => {
    console.log(response, 'response');

    const payload = {
      social,
      token:
        social === 'VKONTAKTE'
          ? response.code
          : social === 'YANDEX'
          ? response.access_token
          : response.accessToken,
    };

    setLoadingAuth(true);

    await signInUsingSocial(payload)
      .then((data) => {
        if (data.token) {
          socialAuth(data.token);
        } else {
          openModal(
            AuthUsingSocialWithPhoneModal,
            {
              social: payload.social,
              token: data.socialInfo.accessToken ?? '',
            },
            { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
          );
        }
        setLoadingAuth(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingAuth(false);
      });
  };

  return (
    <Component>
      <Header>
        <Title>для быстрого входа</Title>
      </Header>
      <Socials>
        {/*<FacebookLogin*/}
        {/*  appId={`${process.env.NEXT_PUBLIC_SOCIAL_LOGIN_FACEBOOK_ID}`}*/}
        {/*  textButton={''}*/}
        {/*  fields="name,email,picture"*/}
        {/*  scope="public_profile,email,user_gender"*/}
        {/*  callback={(response: any) => responseSocial('FACEBOOK', response)}*/}
        {/*  cssClass="facebook-button"*/}
        {/*  icon={<FacebookIcon />}*/}
        {/*/>*/}

        {isBrowser() && (
          <VkLogin
            clientID={`${process.env.NEXT_PUBLIC_SOCIAL_LOGIN_VK_ID}`}
            onSuccess={(response: any) => responseSocial('VKONTAKTE', response)}
          >
            <Social>
              <VKIcon />
            </Social>
          </VkLogin>
        )}

        <GoogleLogin
          clientId={`${process.env.NEXT_PUBLIC_SOCIAL_LOGIN_GOOGLE_ID}`}
          buttonText=""
          onSuccess={(response: any) => responseSocial('GOOGLE', response)}
          icon={false}
          render={(renderProps) => (
            <Social onClick={renderProps.onClick}>
              <GoogleIcon />
            </Social>
          )}
        />

        {isBrowser() && (
          <YandexLogin
            clientID={`${process.env.NEXT_PUBLIC_SOCIAL_LOGIN_YANDEX_ID}`}
            onSuccess={(response: any) => responseSocial('YANDEX', response)}
          >
            <Social>
              <YandexIcon />
            </Social>
          </YandexLogin>
        )}
      </Socials>
    </Component>
  );
}

export default FastLogin;

const Component = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  &:before,
  &:after {
    content: '';
    width: 94%;
    display: inline-block;
    border-bottom: solid 1px ${colors.main};
  }
`;

const Title = styled.span`
  width: 100%;
  min-width: 160px;
  font-size: 14px;
  text-align: center;
  color: ${colors.main};
`;

const Socials = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px -2px 0;

  .facebook-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 2px;
    background-color: ${colors.gray600};
    cursor: pointer;
    user-select: none;
    transition: 0.3s;

    svg {
      width: 100%;
      height: 100%;
      color: ${colors.blue600};
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 2px;
  background-color: ${colors.gray600};
  cursor: pointer;
  user-select: none;
  transition: 0.3s;

  svg {
    width: 100%;
    height: 100%;
    color: ${colors.blue600};
  }

  &:hover {
    opacity: 0.7;
  }
`;

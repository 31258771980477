import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { signInThunk } from '@/store/reducers/auth';
import {
  signIn,
  updatePersonalDataInTheProfile,
} from '@/services/users/users-service';
import { useTypedDispatch } from '@/store/store';
import {
  LoginFormPayload,
  PersonalDataPayload,
} from '@/services/users/typings';

import LoginForm, { LoginFormValues } from './LoginForm';

function LoginFormContainer() {
  const dispatch = useTypedDispatch();
  const [isPhoneSend, setPhoneSend] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  async function handleSendPhone(values: { phone: string }) {
    setLoading(true);

    try {
      await signIn(values).then(() => {
        setPhoneSend(true);
      });
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCheckCode(values: LoginFormPayload) {
    setLoading(true);

    try {
      const response = await dispatch(signInThunk(values));

      if (response?.isNew) {
        setNew(response.isNew);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return Promise.reject(error);
    }
  }

  async function handleSetName(values: PersonalDataPayload) {
    setLoading(true);

    try {
      await updatePersonalDataInTheProfile(values);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      return Promise.reject(error);
    }
  }

  async function handleSubmit(
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) {
    try {
      !isPhoneSend
        ? await handleSendPhone({ phone: values.phone })
        : !isNew
        ? await handleCheckCode({
            phone: values.phone,
            code: values.code,
          })
        : await handleSetName({ firstName: values.firstName });
    } catch (error: any) {
      const errorMap = convertRequestErrorToMap(
        error
      ) as FormikErrors<LoginFormValues>;
      formikHelpers.setErrors(errorMap);
      console.log('error = ', error);
    }
  }

  return (
    <Formik<LoginFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        phone: '',
        code: '',
        firstName: '',
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {(formProps) => (
        <LoginForm
          {...formProps}
          isPhoneSend={isPhoneSend}
          isNew={isNew}
          isLoading={isLoading}
        />
      )}
    </Formik>
  );
}

export default LoginFormContainer;

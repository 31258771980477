import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';

import Header from './components/Header';
import LoginWindow from './components/LoginWindow';

function Login() {
  return (
    <Component>
      <Header />
      <Content>
        <ContentContainerSmall>
          <LoginWindow />
        </ContentContainerSmall>
      </Content>
    </Component>
  );
}

export default Login;

const Component = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray200};
`;

const Content = styled.div`
  flex: 1;
  padding: 90px 0;

  ${media.mobile(css`
    padding: 20px 0;
    background-color: ${colors.white};
  `)}

  ${ContentContainerSmall} {
    height: 100% !important;
    display: flex;
    align-items: center;
  }
`;

import React, { useEffect, useState } from 'react';

import { isBrowser, Nullable } from '@tager/web-core';

import OverviewIcon from '@/assets/svg/overview/type/overview.svg';
import DiscountIcon from '@/assets/svg/overview/type/discount.svg';
import NewsIcon from '@/assets/svg/overview/type/news.svg';
import { BlogCategory } from '@/typings/model';

import { MORE_POSTS_TITLE_NAMES } from './constants';

export const dateFormat = (date: string) => {
  const splitDate = date.split('-');
  return `${
    splitDate && splitDate[2] + '.' + splitDate[1] + '.' + splitDate[0]
  }`;
};

export const getParentPageTitle = (parentRoute: BlogCategory) => {
  return (
    (parentRoute === Object.keys(MORE_POSTS_TITLE_NAMES)[0] &&
      MORE_POSTS_TITLE_NAMES.reviews) ||
    (parentRoute === Object.keys(MORE_POSTS_TITLE_NAMES)[1] &&
      MORE_POSTS_TITLE_NAMES.news) ||
    (parentRoute === Object.keys(MORE_POSTS_TITLE_NAMES)[2] &&
      MORE_POSTS_TITLE_NAMES.offers) ||
    (parentRoute === Object.keys(MORE_POSTS_TITLE_NAMES)[3] &&
      MORE_POSTS_TITLE_NAMES.brand) ||
    MORE_POSTS_TITLE_NAMES.default
  );
};

export function getIconByType(type: BlogCategory): JSX.Element {
  switch (type) {
    case 'reviews':
      return <OverviewIcon />;
    case 'offers':
      return <DiscountIcon />;
    case 'news':
      return <NewsIcon />;
    default:
      return <OverviewIcon />;
  }
}

export function useDOMParser({
  body,
  selectors,
}: {
  body?: Nullable<string>;
  selectors: string;
}): string[] {
  const [state, setState] = useState<string[]>([]);

  useEffect(() => {
    if (!body) {
      return;
    }

    const doc = new DOMParser().parseFromString(body, 'text/html');
    const nodes = Array.from(doc.querySelectorAll<HTMLElement>(selectors));
    const state = nodes.map((node) => node.textContent ?? '');
    setState(state);
  }, [body, selectors]);

  return state;
}

export function insertTitlesAttributesToHtml({
  body,
}: {
  body?: Nullable<string>;
}) {
  if (isBrowser() && body) {
    const doc = new DOMParser().parseFromString(body, 'text/html');
    doc
      .querySelectorAll('h3')
      .forEach((item, index) => item.setAttribute('id', `section-${index}`));
    return doc.documentElement.innerHTML;
  }
}

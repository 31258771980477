import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import AtlantmLogo from '@/assets/svg/preloader/atlantm-secondary-logo.svg';
import Link from '@/components/Link';
import CloseIcon from '@/assets/svg/close-24dp.svg';
import { colors } from '@/constants/theme';

function Header() {
  const router = useRouter();

  function handleCloseLoginPage() {
    if (document.referrer) {
      router.back();
    } else {
      router.push('/');
    }
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <LogoLink to="/">
            <AtlantmLogo />
          </LogoLink>
          <CloseButton onClick={handleCloseLoginPage} type="button">
            <CloseIcon />
          </CloseButton>
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default Header;

const Component = styled.header`
  position: relative;
  height: 80px;
  border-bottom: solid 1px ${colors.gray600};

  ${media.tablet(css`
    height: 60px;
  `)}

  ${ContentContainerSmall} {
    height: 100% !important;
    display: flex;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoLink = styled(Link)`
  svg {
    width: 140px;
    height: 35px;
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${colors.main};
  border-radius: 50%;
  transition: 0.3s;

  svg {
    transition: 0.3s;
    fill: ${colors.black};
  }

  &:hover {
    border-color: ${colors.main};
    background: ${colors.main};

    svg {
      fill: ${colors.white};
    }
  }
`;

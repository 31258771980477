import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { LatestNews } from '@/typings/model';
import { getLastNews } from '@/services/requests';

import LatestNewsCard from './components/LatestNewsCard';

function News() {
  const [latestNews, setLatestNews] = useState<LatestNews[] | null>(null);

  useEffect(() => {
    try {
      (async () => {
        await getLastNews().then((response) => setLatestNews(response));
      })();
    } catch (error) {
      console.log('error = ', error);
    }
  }, []);

  return (
    <Component>
      {latestNews &&
        latestNews.length !== 0 &&
        latestNews.map(({ title, url, datePublished }, index) => (
          <LatestNewsCard
            key={index}
            datePublished={datePublished}
            title={title}
            url={url}
          />
        ))}
    </Component>
  );
}

export default News;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;

  ${media.mobile(css`
    display: none;
  `)}
`;

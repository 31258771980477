import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import {
  signIn,
  signInUsingSocialWithPhone,
} from '@/services/users/users-service';
import { useTypedDispatch } from '@/store/store';
import { signInWithSocialThunk } from '@/store/reducers/auth';

import PhoneCheckForm, { PhoneCheckFormValues } from './PhoneCheckForm';

interface Props {
  social: string;
  token: string;
  closeModal: () => void;
  isLoading: boolean;
  setLoading: (value: boolean) => void;
}

function PhoneCheckFormContainer({
  social,
  token,
  isLoading,
  setLoading,
}: Props) {
  const dispatch = useTypedDispatch();
  const [isPhoneSend, setPhoneSend] = useState<string | null>(null);

  function socialAuth(token: string) {
    dispatch(signInWithSocialThunk(token)).catch((error) => {
      console.log(error);
    });
  }

  function handleSendPhone(
    values: { phone: string },
    formikHelpers: FormikHelpers<PhoneCheckFormValues>
  ) {
    signIn(values)
      .then(() => {
        formikHelpers.resetForm();
        setPhoneSend(values.phone);
      })
      .catch((error) => {
        const errorMap = convertRequestErrorToMap(error) as FormikErrors<{
          phone: string;
        }>;
        formikHelpers.setErrors(errorMap);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleSubmit(
    values: PhoneCheckFormValues,
    formikHelpers: FormikHelpers<PhoneCheckFormValues>
  ) {
    setLoading(true);

    if (!isPhoneSend) {
      handleSendPhone({ phone: values.phone ?? '' }, formikHelpers);
    } else {
      values.phone = isPhoneSend;
      await signInUsingSocialWithPhone(values)
        .then((data) => {
          socialAuth(data.token);
        })
        .catch((error: any) => {
          const errorMap = convertRequestErrorToMap(
            error
          ) as FormikErrors<PhoneCheckFormValues>;
          formikHelpers.setErrors(errorMap);
          setLoading(false);
        });
    }
  }

  return (
    <Formik<PhoneCheckFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        social,
        token,
        phone: '',
        code: '',
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {(formProps) => (
        <React.Fragment>
          <PhoneCheckForm
            {...formProps}
            isLoading={isLoading}
            isPhoneSend={isPhoneSend}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}

export default PhoneCheckFormContainer;

import React from 'react';
import styled, { css } from 'styled-components';
import { Form, FormikProps } from 'formik';
// @ts-ignore
import { IMaskInput } from 'react-imask';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import ArrowIcon from '@/assets/svg/login/arrow-right.svg';
import Spinner from '@/components/Spinner';
import { SignInWithSocialPhonePayload } from '@/services/users/typings';
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField';
import { InputStyles } from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.style';

export type PhoneCheckFormValues = SignInWithSocialPhonePayload;

type Props = FormikProps<SignInWithSocialPhonePayload> & {
  isLoading: boolean;
  isPhoneSend: string | null;
};

function PhoneCheckForm({
  isLoading,
  isPhoneSend,
  handleChange,
  values,
  errors,
}: Props) {
  return (
    <Form autoComplete="off" noValidate>
      <Title>Завершение регистрации</Title>
      <TextFieldWrapper>
        {isPhoneSend ? (
          <CabinetFormField
            error={errors.code ?? ''}
            label={'Код подтверждения'}
          >
            <Input
              type="text"
              id="code"
              name="code"
              mask="0000"
              value={values.code}
              placeholder="Код из СМС-сообщения"
              onChange={handleChange}
            />
          </CabinetFormField>
        ) : (
          <CabinetFormField error={errors.phone ?? ''} label={'Телефон'}>
            <Input
              type="tel"
              id="phone"
              name="phone"
              mask={process.env.NEXT_PUBLIC_PHONE_MASK}
              value={values.phone}
              onChange={handleChange}
            />
          </CabinetFormField>
        )}
        <SubmitButton disabled={isLoading} type={'submit'}>
          {isLoading ? <Spinner absolute={true} /> : <ArrowIcon />}
        </SubmitButton>
      </TextFieldWrapper>
    </Form>
  );
}

export default PhoneCheckForm;

const Title = styled.span`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  color: ${colors.main};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: ${colors.main};
  transition: 0.3s;

  &:hover {
    background: ${colors.main100}0;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background: ${colors.main};
    }
  }
`;

const Input = styled(IMaskInput)`
  ${InputStyles}
`;

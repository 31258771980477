import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import CloseButton from '@/components/modals/shared/CloseButton';

import PhoneCheckFormContainer from './components/PhoneCheckForm/PhoneCheckForm.container';

type AuthModalProps = ModalProps<{
  social: string;
  token: string;
}>;

const AuthUsingSocialWithPhoneModal = ({
  innerProps: { social, token },
  closeModal,
}: AuthModalProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  useLockBodyScroll();

  return (
    <Component>
      <CloseButton onClick={closeModal} />

      <Left>
        <Text>
          Добавьте, пожалуйста, номер телефона. Так, вам будет проще
          авторизоваться в следующий раз. Кроме того, если вы являетесь нашим
          клиентом, то в личном кабинете вам будет доступна история обслуживания
          авто и накопления бонусов.
        </Text>
      </Left>
      <Right>
        <PhoneCheckFormContainer
          social={social}
          token={token}
          closeModal={closeModal}
          isLoading={isLoading}
          setLoading={(value: boolean) => setLoading(value)}
        />
      </Right>
    </Component>
  );
};

export default AuthUsingSocialWithPhoneModal;

const Component = styled.div`
  display: flex;
  width: calc(100vw - 20px);
  max-width: 990px;
  background-color: ${colors.white};
  padding: 40px 60px 60px;
  border-radius: 10px;

  ${media.tablet(css`
    padding: 40px;
  `)}

  ${media.mobile(css`
    display: block;
    padding: 40px 20px;
  `)}
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 360px;
  padding: 20px 15px;

  ${media.mobile(css`
    width: 100%;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;
  padding: 20px 15px;

  ${media.mobile(css`
    width: 100%;
  `)}
`;

const Text = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: ${colors.blue600};
  font-weight: 500;

  ${media.tabletSmall(css`
    font-size: 16px;
    line-height: 24px;
  `)}
`;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Spinner from '@/components/Spinner';

import FastLogin from './components/FastLogin';
import LoginFormContainer from './components/LoginForm/LoginForm.container';
import News from './components/News';

function LoginWindow() {
  const [isLoadingAuth, setLoadingAuth] = useState<boolean>(false);

  return (
    <Component>
      {isLoadingAuth && (
        <ComponentInner>
          <Spinner absolute={true} isSecondary={true} />
        </ComponentInner>
      )}
      <News />
      <LoginContent>
        <LoginFormContainer />
        <FastLogin setLoadingAuth={(value: boolean) => setLoadingAuth(value)} />
      </LoginContent>
    </Component>
  );
}

export default LoginWindow;

const Component = styled.div`
  display: flex;
  max-width: 760px;
  width: 100%;
  margin: auto;
  flex: 0 0 auto;
  background-color: ${colors.white};
  padding: 40px;
  box-shadow: 0 0 80px 0 #d7e8ff, 0 0 20px rgb(0 0 0 / 20%);
  position: relative;

  ${media.mobile(css`
    margin: 0;
    height: 100%;
    padding: 0;
    box-shadow: none;
  `)}
`;

const ComponentInner = styled.div`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: ${colors.white};
`;

const LoginContent = styled.div`
  flex: 0 0 370px;
  padding: 0 30px;

  ${media.mobile(css`
    flex: auto;
    padding: 0;
  `)}
`;

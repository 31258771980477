import React from 'react';
import styled from 'styled-components';

import { LatestNews } from '@/typings/model';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { dateFormat } from '@/modules/SinglePost/components/utils';
import { TextLimit } from '@/components/styles';

function LatestNewsCard({ title, url, datePublished }: LatestNews) {
  return (
    <ComponentLink to={url}>
      <Content>
        <Title lines={2}>{title}</Title>
        <Date>{dateFormat(datePublished)}</Date>
      </Content>
    </ComponentLink>
  );
}

export default LatestNewsCard;

const Title = styled.p<{ lines?: number }>`
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
  color: #657691;

  ${TextLimit};
`;

const ComponentLink = styled(Link)`
  margin: 30px 0;

  &:hover {
    ${Title} {
      color: ${colors.main};
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 73px;
  padding-left: 15px;
  border-left: solid 3px ${colors.main};
`;

const Date = styled.span`
  font-size: 10px;
  line-height: 14px;
  margin-top: 5px;
  color: #71819d;
`;
